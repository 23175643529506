/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'RobotoBold';
    src: url('../fonts/RobotoBold.eot');
    src: url('../fonts/RobotoBold.eot') format('embedded-opentype'),
         url('../fonts/RobotoBold.woff2') format('woff2'),
         url('../fonts/RobotoBold.woff') format('woff'),
         url('../fonts/RobotoBold.ttf') format('truetype'),
         url('../fonts/RobotoBold.svg#RobotoBold') format('svg');
}
@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/RobotoRegular.eot');
    src: url('../fonts/RobotoRegular.eot') format('embedded-opentype'),
         url('../fonts/RobotoRegular.woff2') format('woff2'),
         url('../fonts/RobotoRegular.woff') format('woff'),
         url('../fonts/RobotoRegular.ttf') format('truetype'),
         url('../fonts/RobotoRegular.svg#RobotoRegular') format('svg');
}
@font-face {
    font-family: 'RobotoMedium';
    src: url('../fonts/RobotoMedium.eot');
    src: url('../fonts/RobotoMedium.eot') format('embedded-opentype'),
         url('../fonts/RobotoMedium.woff2') format('woff2'),
         url('../fonts/RobotoMedium.woff') format('woff'),
         url('../fonts/RobotoMedium.ttf') format('truetype'),
         url('../fonts/RobotoMedium.svg#RobotoMedium') format('svg');
}
@font-face {
    font-family: 'RobotoLight';
    src: url('../fonts/RobotoLight.eot');
    src: url('../fonts/RobotoLight.eot') format('embedded-opentype'),
         url('../fonts/RobotoLight.woff2') format('woff2'),
         url('../fonts/RobotoLight.woff') format('woff'),
         url('../fonts/RobotoLight.ttf') format('truetype'),
         url('../fonts/RobotoLight.svg#RobotoLight') format('svg');
}
@font-face {
    font-family: 'RobotoItalic';
    src: url('../fonts/RobotoItalic.eot');
    src: url('../fonts/RobotoItalic.eot') format('embedded-opentype'),
         url('../fonts/RobotoItalic.woff2') format('woff2'),
         url('../fonts/RobotoItalic.woff') format('woff'),
         url('../fonts/RobotoItalic.ttf') format('truetype'),
         url('../fonts/RobotoItalic.svg#RobotoItalic') format('svg');
}

$regular: 'RobotoRegular';
$bold: 'RobotoBold';
$light: 'RobotoLight';
$italic: 'RobotoItalic';
$medium: 'RobotoMedium';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	padding-top: 165px;
	margin: 0;
	@media screen and (max-width: 1199px) {
		padding-top: 83px;
	}
}

h1 {
	font-family: $regular;
	font-size: 38px;
	color: #105e90;
	line-height: 1;
	margin-bottom: 25px;
	@media screen and (max-width: 991px) {
		font-size: 32px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	font-family: $bold;
	font-size: 36px;
	color: #4e4e4e;
	margin: 0 0px 20px;	
	a {
		font-family: $bold;
		font-size: 36px!important;
		color: #4e4e4e;
		&:hover {
			color: #105e90;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #4e4e4e;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #4e4e4e;
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #4e4e4e;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #4e4e4e;
}
a {
	color: #4e4e4e;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #105e90;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
	}
}
.line {
	display: table;
	min-width: 490px;
	position: relative;
	text-align: left;
	padding-bottom: 30px;
	&:before, &:after {
		content: '';
		height: 5px;
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&:before {
		width: 95px;
		background-color: #105e90;
		z-index: 1;
	}
	&:after {
		background-color: #128fc3;
	}
	@media screen and (max-width: 1199px) {
		width: 100%;
		min-width: 0px;
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 20px;
	}
}
input, textarea, button, select {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
	a:hover {
		text-decoration: none;
	}
}
.main, .page, header, footer {
	textarea, input[type="text"], input[type="email"] {
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 5px 15px;
		border: 1px solid #9e9e9e;
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
	width: auto!important;
	background-color: #128fc3!important;
	font-family: $regular!important;
	font-size: 16px;
	text-decoration: none!important;
	color: #fff!important;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #128fc3;
	padding: 5px 20px!important;
	transition: 300ms;
	line-height: 1;
	&:focus, &:active {
		background-color: #128fc3!important;
		color: #fff!important;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #128fc3!important;
	}	
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 18px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

.square--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;
		&:before {
			content: '';
			width: 5px;
			height: 5px;
			background-color: #128fc3;
			position: absolute;
			left: 0;
			top: 8px;
		}
		a:hover {
			text-decoration: none!important;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 18px;
		position: absolute;
		top: 2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 14px;
	top: 4px;
}

/* header */
header {
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	> .container {
		padding: 20px 15px;
	}
	.container-fluid {
		min-height: 50px;
		background-color: #128fc3;
	}
	ul {
		@include ul-default;
	}

	/* header logo */
	.logo {
		@include inline-block;
	}
	/* header logo */

	.header--col {
		margin-left: 40px;
		@include inline-block;
	}

	/* header slogan */
	.header--slogan {
		display: block;
		font-family: $bold;
		font-size: 18px;
		text-transform: uppercase;
		color: #105e90;
		margin: 15px 0 5px;
	}
	/* header slogan */

	/* header location */
	.location {
		display: block;
		position: relative;
		padding-left: 25px;
		font-size: 18px;
		&:after {
			content: '\f041';
			display: block;
			font-family: 'fontAwesome';
			color: #105e90;
			position: absolute;
			left: 0;
			top: 0;
			font-size: 20px;
		}
	}
	/* header location */
	
	/* header phones */
	.phones {
		position: relative;
		padding-left: 25px;
		float: right;
		margin-top: 15px;
		&:after {
			content: '\f095';
			font-family: 'fontAwesome';
			font-size: 22px;
			position: absolute;
			left: 0;
			top: 2px;
			color: #105e90;
		}
		li {
			a {
				font-size: 18px;
				pointer-events: none;
			}
		}
	}
	/* header phones */

	/* header nav */
	.nav {
		font-size: 0;
		line-height: 1;
		@include inline-block;
		> ul {
			> li {
				margin: 15px 0;
				@include inline-block;
				> a {
					display: block;
					position: relative;
					font-size: 18px;
					color: #fff;
					padding: 0 15px;
					border-right: 1px solid #fff;
				}
				&.active > a {
					text-decoration: underline;
				}
				&:first-of-type > a {
					padding-left: 0;
				}
				&:last-of-type > a {
					border-right: none;
				}
			}
		}
	}
	/* header nav */

	/* header langs */
	.langs {
		display: block;
		max-height: 26px;
		position: absolute;
		top: 13px;
		right: 70px;
		padding: 3px 25px 3px 5px;
		box-sizing: border-box;
		transition: 400ms;
		overflow: hidden;
		z-index: 1;
		&:after {
			content: '';
			width: 0;
			height: 0;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 7px solid #fff;
			position: absolute;
			top: 8px;
			right: 5px;
		}
		li {
			display: block;
			line-height: 1;
			margin-bottom: 8px;
			a {
				display: block;
				text-decoration: none;
				color: #fff;
				font-size: 16px;
				text-decoration: underline;
				&:hover {
					color: #fff;
					text-decoration: none;
				}
			}
			&.active a {
				color: #fff;
				text-decoration: none;
			}
		}
		&:hover {
			max-height: 150px;
			background-color: rgba(18, 143, 195, 0.7);
		}
	}
	/* header langs */

	/* header search */
	.search--btn {
		float: right;
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #fff;
		margin: 14px 0 0 0;
		@include inline-block;
		&:after {
			content: '\f002';
		}
		&:hover {
			text-decoration: none;
		}
	}
	.search {
		display: none;
		width: 250px;
		position: absolute;
		right: 15px;
		top: 7px;
		background-color: #fff;
		padding-right: 20px;
		z-index: 1;
		input[type="text"] {
			width: 100%;
			outline: none;
			border: 1px solid transparent;
		}
		.close {
			display: block;
			font-family: 'fontAwesome';
			font-size: 16px;
			color: #128fc3;
			opacity: 1;
			position: absolute;
			right: 5px;
			top: 7px;
			&:after {
				content: '\f00d';
			}
		}
	}
	/* header search */

	// &.fixed {
	// 	.container-fluid {
	// 		position: fixed;
	// 		left: 0;
	// 		right: 0;
	// 		top: 0;
	// 		z-index: 1;
	// 	}
	// }
	@media screen and (max-width: 1199px) {
		background-color: #128fc3;
		position: fixed;
		> .container {
			padding: 5px 15px;
		}
		.container-fluid {
			min-height: 0px;
		}
		.phones {
			display: none;
			background-color: rgba(18, 143, 195, 0.9);
			padding: 10px;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			margin: 0;
			z-index: 2;
			&:after {
				display: none;
			}
			ul li {
				display: block;
				text-align: center;
				a {
					color: #fff;
				}
			}
		}
		.phones--btn {
			display: block;
			position: absolute;
			top: 25px;
			left: 50%;
			margin-left: -430px;
			font-family: 'fontAwesome';
			font-size: 24px;
			color: #fff;
			&:after {
				content: '\f095';
			}
			&:hover {
				text-decoration: none;
			}
		}
		.logo {
			display: table;
			width: 60px;
			margin: 0 auto;
			img {
				display: block;
				width: 100%;
			}
		}
		.header--col {
			display: block;
			text-align: center;
			margin: 0;
		}
		.header--slogan {
			margin: 5px 0 0px;
			font-size: 12px;
			color: #fff;
		}
		.location {
			display: none;
		}

		.nav {
			display: none;
			width: 100%;
			> ul > li {
				display: block;
				a {
					padding: 5px 10px!important;
					border: none;
				}
			}
		}
		.langs {
			right: 45px;
			top: -53px;
		}
		.search--btn {
			position: absolute;
			top: -52px;
			right: 15px;
			margin: 0;
		}
		.search {
			top: 0;
			border: 1px solid #128fc3;
		}

		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 50%;
			top: 23px;
			padding: 0;
			margin: 0 0 0 -475px;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.nav--btn {
			margin-left: -360px;
		}
		.phones--btn {
			margin-left: -315px;
		}
	}
	@media screen and (min-width: 768px) {
		.container {
			position: relative;
		}
	}
	@media screen and (max-width: 767px) {
		min-height: 83px;
		.search--btn {
			right: 15px;
			top: 25px;
		}
		.search {
			top: 20px;
		}
		.langs {
			top: 26px;
		}
		.nav--btn {
			margin: 0;
			left: 10px;
		}
		.phones {
			ul li a {
				pointer-events: auto;
			}
		}
		.phones--btn {
			left: 55px;
			margin: 0;
		}
		.header--slogan {
			font-size: 10px;
			margin-top: 6px;
		}
		.nav > ul > li a {
			padding: 2px 0px!important;
		}
	}
	@media screen and (max-width: 480px) { 
		.header--slogan {
			font-size: 8px;
		}
	}
}
/* header */