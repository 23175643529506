/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider--block {
	position: relative;
	.slider--for {
		.slick-slide {
			position: relative;
			overflow: hidden;
			outline: none;
			img {
				min-width: 100%;
			}
			&:after {
				content: '';
				background-color: rgba(255,255,255,0.3);
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
			}
			&:before {
				content: '';
				width: 30%;
				background: linear-gradient(to left, transparent, #fff);
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
			}
			.slider--for__block {
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -50px 0 0 -570px;
				z-index: 1;
				.slider--for__block__title {
					display: block;
					font-family: $bold;
					font-size: 36px;
					text-transform: uppercase;
					color: #105e90;
				}
				.slider--for__block__slogan {
					display: block;
					padding: 5px;
					background-color: #128fc3;
					font-size: 16px;
					color: #fff;
				}
			}
		}
		.slick-dots {
			bottom: 30px;
			li {
				margin: 0px 5px;
				button {
					width: 15px;
					height: 15px;
					background-color: #fff;
					border-radius: 100%;
					border: 2px solid #128fc3;
					transition: 300ms;
					&:before {
						display: none;
					}
					&:hover {
						background-color: #128fc3;
					}
				}
				&.slick-active {
					button {
						background-color: #128fc3;
					}
				}
			}
		}
	}
	.slider--nav {
		display: none;
		width: 1170px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -50px;
		padding: 0 15px;
		margin: 0 auto;
		z-index: 1;
		.slick-track {
			padding: 50px 0;
		}
		.slick-slide {
			position: relative;
			overflow: hidden;
			outline: none;
			cursor: pointer;	
			transition: 400ms;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #128fc3;
				opacity: 0;
				transition: 400ms;
			}
			img {
				display: block;
				width: 100%;
			}
			&:hover:after {
				opacity: 0.5;
			}
			&.slick-current {
				transform: scale(1.2);
				transform-origin: center bottom;
				z-index: 1;
				&:after {
					opacity: 0.5;
				}
			}
		}
		.slick-arrow {
			width: 45px;
			height: 45px;
			border-radius: 100%;
			background-color: #128fc3;
			background-image: url(../img/arrow.png);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 30px;
			transform: translate(0);
			transition: 300ms;
			top: -30px;
			margin: 0;
			z-index: 1;
			&:before {
				display: none;
			}
			&.slick-prev {
				transform: rotate(-180deg);
			}
			&.slick-next {
				right: 100px;
			}
			&.slick-prev {
				left: auto;
				right: 165px;
			}
			&:hover {
				background-color: #105e90;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		.slider--for .slick-slide .slider--for__block {
			margin: -50px 0 0 0;
			left: 15px;
		}
		.slider--nav {
			width: 100%;
		}
	}
	@media screen and (max-width: 768px) {
		.slider--for {
			.slick-slide img {
				width: 100%;
			}
			.slick-dots {
				bottom: -40px;
			}
		}
		.slider--nav {
			position: relative;
			bottom: 0;
			.slick-track {
				padding: 0;
			}
			.slick-slide {
				&.slick-current {
					transform: scale(1);
				}
			}
			.slick-arrow {
				top: -50px;
				width: 30px;
				height: 30px;
				background-size: 20px;
				&.slick-next {
					right: 30px;
				}
				&.slick-prev {
					right: 70px;
				}
			}
		}
	}
	@media screen and (max-width: 680px) {
		.slider--for {
			.slick-slide {
				img {
					width: auto;
					height: 225px;
				}
				.slider--for__block {
					top: auto;
					bottom: 15px;
					margin: 0;
					.slider--for__block__slogan {
						font-size: 14px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slider--for {
			.slick-slide {
				.slider--for__block {
					right: 30px;
					.slider--for__block__title {
						font-size: 28px;
					}
				}
			}
		}
		.slider--nav {
			.slick-arrow {
				display: none!important;
			}
		}
	}
}
/* main slider */

/* main about */
.about {
	.col-sm-6 {
		img {
			display: block;
			width: 100%;
		}
	}
	.about__list {
		margin-top: 25px;
		text-align: justify;
		text-align-last: justify;
		ul {
			@include ul-default;
			li {
				@include inline-block;
				font-family: $bold;
				font-size: 40px;
				text-transform: uppercase;
				text-align: left;
				color: #e9e9e9;
				position: relative;
				padding-left: 40px;
				line-height: 1;
				&:after {
					content: '\f00c';
					display: block;
					font-family: 'fontAwesome';
					font-size: 30px;
					color: #e7f0f7;
					position: absolute;
					left: 0;
					bottom: 3px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.about__list {
			ul li {
				font-size: 35px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.about__list {
			ul li {
				font-size: 24px;
				&:after {
					font-size: 25px;
				}
			}
		}
	}
	@media screen and (max-width: 700px) {
		.about__list {
			text-align: left;
			text-align-last: left;
			margin-top: 20px;
			ul li {
				display: block;
				padding-left: 25px;
				margin: 10px 0;
				font-size: 20px;
				&:after {
					font-size: 20px;
				}
			}
		}
	}
}
/* main about */

/* main services */
.services {
	position: relative;
	overflow: hidden;
	color: #fff;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.6);
	}
	> img {
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.container {
		position: relative;
		z-index: 1;
	}
	h2 {
		color: #fff;
	}
	.col-md-3 {
		position: relative;
		padding-left: 80px;
		.num {
			display: block;
			position: absolute;
			top: 0;
			left: 15px;
			font-family: $bold;
			font-size: 48px;
			color: #128fc3;
			line-height: 1;
			span {
				color: #105e90;
			}
		}
	}
	.services__title {
		display: block;
		font-family: $bold;
		font-size: 16px;
		color: #fff;
		margin-bottom: 10px;
	}
	@media screen and (max-width: 1600px) {
		> img {
			width: auto;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
	}
	@media screen and (max-width: 991px) {
		.col-md-3 {
			margin-bottom: 20px;
		}
	}
	@media screen and (max-width: 768px) {
		.col-md-3 {
			padding-left: 60px;
			.num {
				font-size: 30px;
			}
		}
	}
}
/* main services */

/* main news */
.news {
	h2 {
		margin-bottom: 50px;
	}
	.news__col {
		display: block;
		min-height: 300px;
		position: relative;
		padding-left: 54%;
		color: #4e4e4e;
		box-sizing: border-box;
		.news__col__img {
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0,0,0,0.5);
				transition: 300ms;
				opacity: 0;
			}
			img {
				display: block;
				width: 100%;
			}
		}
		.news__col__date {
			display: block;
			color: #128fc3;
			margin: 10px 0;
		}
		h6 {
			margin: 0 0 10px 0;
			transition: 300ms;
		}
		&:hover {
			.news__col__img:after {
				opacity: 1;
			}
			h6 {
				color: #105e90;
			}
		}
	}
	@media screen and (max-width: 991px) {
		h2 {
			margin-bottom: 25px;
		}
	}
	@media screen and (max-width: 767px) {
		.news__col {
			min-height: 400px;
		}
	}
	@media screen and (max-width: 580px) {
		.news__col {
			min-height: 1px;
		}
	}
	@media screen and (max-width: 480px) {
		.news__col {
			padding-left: 90px;
			margin-bottom: 25px;
			.news__col__img {
				width: 70px;
			}
		}
	}
}
/* main news */

/* main content */