/* footer */
footer {
	background-color: #128fc3;
	color: #fff;
	font-size: 16px;
	padding-top: 25px;
	ul {
		@include ul-default;
		li.active a {
			text-decoration: underline;
		}
	}
	h6 {
		color: #fff;
		margin: 0 0 20px 0;
	}
	a {
		display: block;
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	.check--list {
		li {
			font-size: 16px;
			&:before {
				color: #fff;
			}
		}
	}
	.footer--logo {
		display: block;
		color: #fff;
		font-family: $bold;
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 20px;
		&:hover {
			text-decoration: none;
		}
		img {
			max-width: 135px;
			@include inline-block;
		}
		p {
			width: calc(100% - 155px);
			margin-left: 15px;
			@include inline-block;
		}
	}
	.footer__col {
		width: 32.5%;
		padding-right: 10px;
		box-sizing: border-box;
		@include inline-block;
		&.col--nav {
			min-height: 155px;
			padding-left: 50px;
			border-left: 1px solid #fff;
			ul li {
				position: relative;
				margin: 5px 0;
				padding-left: 15px;
				&:after {
					content: '\f105';
					font-family: 'fontAwesome';
					font-size: 14px;
					color: #fff;
					position: absolute;
					top: 2px;
					left: 0;
				}
			}
		}
		&.col--contacts {
			ul li {
				margin-bottom: 10px;
			}
		}
		.col--phone {
			li {
				margin-bottom: 2px!important;
			}
		}
		.socials {
			padding-left: 25px;
			ul {
				li {
					margin: 10px 10px;
					@include inline-block;
					a {
						font-size: 22px;
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}

	.container-fluid {
		background-color: #fff;
		padding: 15px 0;
		margin-top: 25px;
		.container {
			position: relative;
		}
		p {
			color: #4e3e3e;
			margin: 0;
			@include inline-block;
			&.artmedia {
				width: 260px;
				position: relative;
				padding-right: 120px;
				float: right;
				box-sizing: border-box;
				a {
					position: absolute;
					top: -5px;
					right: 0;
				}
			}
		}
		.socials {
			width: 150px;
			position: absolute;
			left: 0;
			right: 0;
			top: -5px;
			margin: 0 auto;
			text-align: center;
			ul li a:hover {
				color: #466379;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.footer__col {
			width: 31%;
			&.col--nav {
				padding-left: 25px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 15px;
		.col-lg-4 {
			margin-bottom: 20px;
		}
		.footer--logo {
			display: block;
			text-align: center;
			margin-bottom: 10px;
			img {
				display: block;
				width: 100px;
				margin: 0 auto;
			}
			p {
				display: block;
				width: 100%;
				margin: 5px 0;
			}
		}
		.footer__col {
			&.col--nav {
				min-height: 1px;
				padding-left: 0;
				border: none;
			}
		}
	}
	@media screen and (max-width: 680px) {
		.footer__col.col--nav {
			display: none;
		}
		.container-fluid {
			margin-top: 15px;
			p {
				display: block;
				text-align: center;
				&.artmedia {
					text-align: left;
					float: none;
					margin: 10px auto 0;
				}
			}
			.socials {
				position: relative;
				top: 0px;
				margin-bottom: 10px;
			}
		}
	}
	@media screen and (max-width: 560px) {
		h6 {
			margin-bottom: 10px;
		}
		.footer__col {
			display: block;
			width: 100%;
			padding-right: 0;
			margin-bottom: 10px;
		}
	}
}
/* footer */