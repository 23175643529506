/* page content */
.page {
	padding-bottom: 50px;
	.news1, .news {
		.date {
			color: #105e90;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #105e90;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #105e90;
		}
	}
	a {
		text-decoration: underline;
		color: #105e90;;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			text-decoration: none;
		}
	}
	ul.advices-list li .name a:link, ul.advices-list li .name a:visited {
		color: #105e90;
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 25px;
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	margin: 40px 0 0;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #105e90;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '/';
				margin: 2px 2px 0px 7px;
				color: #4e4e4e;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
	@media screen and (max-width: 991px) {
		margin: 20px 0 0 0;
	}
}
/* breadcrumbs */

/* page catalog */
.services--inslide {
	.services--inslide__col {
		display: block;
		margin-bottom: 30px;
		text-decoration: none;
		.services--inslide__col__img {
			overflow: hidden;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(18, 143, 195, 0.5);
				transition: 300ms;
				opacity: 0;
			}
			.more {
				display: block;
				text-align: center;
				text-transform: uppercase;
				font-size: 18px;
				color: #fff;
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				margin: -15px auto;
				transform: 300ms;
				opacity: 0;
				z-index: 1;
			}
			img {
				display: block;
				width: 100%;
			}
		}
		h5 {
			display: block;
			padding: 18px 10px;
			background-color: #128fc3;
			color: #fff;
			text-align: center;
			margin: 0;
		}
		&:hover {
			.services--inslide__col__img {
				&:after {
					opacity: 1;
				}
				.more {
					opacity: 1;
				}
			}
		}
	}
}
/* page catalog */

/* page teachers */
.teachers {
	.col-md-3, .col-md-9 {
		margin-bottom: 25px;
	}
	img {
		display: block;
		max-width: 100%;
		box-sizing: border-box;
		border: 1px solid #9e9e9e;
	}
	h4 {
		font-family: $regular;
		font-size: 20px;
		color: #105e90;
		margin-bottom: 20px;
	}
}
/* page teachers */

/* page question */
.question--btn {
	color: #105e90;
	text-decoration: none!important;
	border-bottom: 1px dashed #105e90;
	&:hover {
		border-bottom: 1px dashed transparent;
	}
}
.question--form {
	display: none;
	width: 360px;
	input[type="text"],
	input[type="email"] {
		width: 100%;
		border-left: 1px solid transparent;
		border-top: 1px solid transparent;
		border-right: 1px solid transparent;
		border-bottom: 1px solid #9e9e9e;
		margin-bottom: 25px;
	}
	textarea {
		width: 100%;
	}
	.g-recaptcha {
		margin: 15px 0;
	}
	.btn {
		padding: 5px 40px!important;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
	}
	@media screen and (max-width: 480px) {
		margin-bottom: 50px;
		.g-recaptcha {
			transform: scale(0.86);
			transform-origin: 100% 0;
			text-align: right;
			> div {
				display: inline-block;
			}
		}
		.btn {
			float: right;
			margin-top: 0px;
		}
	}
}

.accordion {
	margin: 25px 0 0 0;
	ul {
		@include ul-default;
		li {
			display: block;
			padding: 30px 48px;
			border-bottom: 1px solid #9e9e9e;
			.accordion__title {
				display: block;
				font-family: $bold;
				position: relative;
				cursor: pointer;
				&:before, &:after {
					content: '';
					width: 14px;
					height: 4px;
					background-color: #105e90;
					position: absolute;
					left: -30px;
					top: 8px;
					transition: 300ms;
				}
				&:after {
					transform: rotate(90deg);
				}
				&.open {
					&:after {
						opacity: 0;
					}
				}
			}
			.accordion__block {
				display: none;
				padding: 25px 0 0px;
			}
		}
	}
}
/* page question */

/* page pagination */
.pagination {
	display: block;
	text-align: center;
	ul {
		@include ul-default;
		li {
			margin: 5px 4px 5px 0px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 30px;
				height: 30px;
				display: block;
				border: 1px solid transparent;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 6px 2px 0px;
				outline: none;
				line-height: 1;
				&:hover {
					color: #105e90;
				}
			}
			&.active {
				a {
					color: #105e90;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					border: 1px solid transparent;
					background-color: transparent!important;
					transition: 0ms;
					&:hover {
						color: #105e90;
					}
					&:after {
						display: block;
						font-family: 'fontAwesome';
						font-size: 20px;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -12px auto;
						transition: 350ms;
					}
				}
			}	
			&.prev a {
				&:after {
					content:'\f104';
				} 
			}
			&.next a {
				&:after {
					content: '\f105';
				} 
			}
		}
	}
}
/* page pagination */

/* page contacts */
.contacts {
	.left {
		ul {
			@include ul-default;
			li {
				margin-bottom: 15px;
				a {
					display: block;
					text-decoration: none;
				}
			}
		}
		.contacts--form {
			display: block;
			margin: 45px 0;
			h5 {
				margin-bottom: 25px;
			}
			input[type="text"],
			input[type="email"] {
				width: 100%;
				border-left: 1px solid transparent;
				border-top: 1px solid transparent;
				border-right: 1px solid transparent;
				border-bottom: 1px solid #9e9e9e;
				margin-bottom: 25px;
			}
			textarea {
				width: 100%;
			}
			.g-recaptcha {
				transform: scale(0.86);
				transform-origin: 0 0;
				margin: 15px 0;
			}
		}
	}
	.map {
		padding-left: 50px;
	}
	@media screen and (max-width: 991px) {
		.map {
			padding-left: 0;
		}
	}
}
/* page contacts */

/* modal */
.modal {
	.modal-dialog {
		width: 420px;
	}
	.modal-content {
		border-radius: 0px;
		h4 {
			margin: 0;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		form {
			display: block;
			padding-bottom: 40px;
			margin-top: 25px;
			input[type="text"],
			input[type="email"],
			textarea {
				display: block;
				border: 1px solid #cecece;
				width: 100%;
				padding:  9px 15px;
				margin-bottom: 15px;
			}
			.g-recaptcha {
				margin-bottom: 15px;
				text-align: right;
				> div {
					@include inline-block;
				}
			}
			.btn {
				float: right;
			}
		}
	}
	&#modal--application, &#modal--contacts {
		.modal-content {
			h4 {
				text-align: center;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content {
			h4 {
				font-size: 18px;
			}
		}
		.modal-dialog {
			width: auto;
		}
	}
	@media screen and (max-width: 360px) {
		.modal-content {
			form {
				padding-bottom: 115px;
				.g-recaptcha {
					transform: scale(0.8);
					transform-origin: 100% 0;
					> div {
						float: right;
					}
				}
			}
		}
	}
}

.inspection--btn {
	position: fixed;
	top: 50%;
	right: -91px;
	transform: rotate(-90deg);
	padding: 10px 25px!important;
	z-index: 1;
	@media screen and (max-width: 768px) {
		height: auto;
		font-size: 14px!important;
		padding: 5px 10px!important;
		right: -71px;
	}
}

.call--btn {
	position: fixed;
	bottom: 35px;
	right: 35px;
	display: block;
	background-color: #c22c2a;
	font-family: 'FontAwesome';
	border-radius: 100%;
	text-decoration: none!important;
	width: 80px;
	height: 80px;
	text-align: center;
	padding: 25px 5px 5px 5px;
	box-shadow: 0px 0px 35px rgba(0,0,0,0.7);
	outline: none;
	z-index: 2;
	&:hover {
		animation-name: phone;
	  	animation-iteration-count: 1;
	  	animation-duration: 0.7s;
	}
	i {
		display: block;
		font-size: 34px;
		color: #fff;
	}
	&:after,&:before  {
		content: '';
		width: 90%;
		height: 90%;
		background-color: transparent;
		border: 1px solid #c22c2a;
		position: absolute;
		top: 5%;
		left: 5%;
		border-radius: 100%;
		animation-iteration-count: infinite;
	    animation-duration: 1.2s;
	    animation-name: ring;
	}
	&:after {
		animation-delay: 0.6s;
	}
	&:before {
		animation-delay: 0.8s;
	}
	@media screen and (max-width: 991px) {
		width: 50px;
		height: 50px;
		padding-top: 13px;
		right: 15px;
		bottom: 30px;
		i {
			font-size: 24px;
		}
	}
}
@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */